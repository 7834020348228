const links: any = {
	private: "/vehicles",
	leasing: "/leasing",
	finance: "/finance",
	used: "/used-evs",
	commercial: "/commercial-vehicles",

	finance_partner: "/partner/bank-of-ireland",
	insurance_partner: "/partner/insurance",
	servicing_partner: "/partner/best-drive",

	all_electric: "/vehicles/all?fuel_type=BEV",
	plug_in: "/vehicles/all?fuel_type=PHEV",
	suv: "/vehicles/all?body_type=sports-utility-vehicle",
	below_40k: "/vehicles/all?price_max=40000",

	saloon: "/vehicles/all?body_type=saloon",
	estate: "/vehicles/all?body_type=estate",
	hatchback: "/vehicles/all?body_type=hatchback",
	mpv: "/vehicles/all?body_type=multi-purpose",

	ev_guide: "/ev-guide",

	video: "/video",
	post: "/post",

	articles: "/learn/articles",
	videos: "/learn/video-review",
	asknevo: "/learn/ask-nevo",
	newsfeed: "/learn/newsfeed",

	tradebid_website: "https://trade-bid.ie",
	instabid_website: "https://instabid.ie",
	nevo_website: "https://nevo.ie",
	nevo_ev_talk_show: "https://www.eventbrite.com/e/919505324177?aff=oddtdtcreator",
	ev_talk_show_page: "/electric-vehicle-show"
};

export default links;
