import axios from 'axios';
import nevo from '../api/nevo';
import sweep from '../api/sweep';
import getSession from './SessionManager';
import endpoints from '../helper/endpoints';
import { commercialVehicles, imaginStudioURL, vehicleTypes } from "../helper/constants";
import isQAorDev from './DevelopmentMode';
import slugify from 'react-slugify';

import IOption from '../interfaces/IOption';
import iFilters from '../interfaces/iFilters';
import IAnalyticsUser from '../interfaces/IAnalyticsUser';
import { MIN_YEAR_VALUE, usedFuelTypeOptions } from '../helper/filters';
import { categoriesSubtitutes } from '../helper/filters_learn';

export default class Backend {
  protected static cancellableRequest(url: string, method: string, body?: any, api = nevo) {
    const token = axios.CancelToken.source();
    
    if (method === 'GET') {
      return {
        promise: api.get(url, { cancelToken: token.token }),
        cancel: token.cancel
      }
    }
    // DEFINITELY POST
    else {
      return {
        promise: api.post(url, body, { cancelToken: token.token }),
        cancel: token.cancel
      }
    }
  }

  protected static addArrayToBody(body: any, array: IOption[], field: string) {
    if (array.length > 0) {
      body[field] = array.map(({value}) => value)
    }
  }
  
  // For Pages
  static getPageByURL(url: string) {
    const requestURL = `${endpoints.page}/url/${url}`
    
    return this.cancellableRequest(requestURL, 'GET');
  }
  // End of Pages

  // For Components
  static getComponentBySlug(slug: string) {
    const requestURL = `${endpoints.component}/url/${slug}`

    return this.cancellableRequest(requestURL, 'GET');
  }
  // End of Components

  // For Posts (blog)
  static getPostBySlug(slug: string) {
    return nevo.get(`${endpoints.post}/url/${slug}`);
  }
  // End of Posts (blog)
  
  // For Videos (blog)
  static getVideoBySlug(slug: string) {
    return nevo.get(`${endpoints.video}/slug/${slug}`);
  }
  // End of Videos (blog)

  // For Partners
  static getPartnerBySlug(slug: string) {
    return nevo.get(`${endpoints.partner}/url/${slug}`);
  }
  static getAllPartners() {
    const requestURL = `${endpoints.partner}/all/?active=true`

    return this.cancellableRequest(requestURL, 'GET');
  }
  // End of Partners

  // Vehicles
  static getAllMakes(commercial?: boolean) {
    const type = commercial ? `&type=${vehicleTypes.commercial}` : '';
    const requestURL = `${endpoints.manufacturer}/all/?active_only=1${type}`

    return this.cancellableRequest(requestURL, 'GET');
  }

  static getVehiclesByIDs(nevoIDs: number[]) {
    const body: any = {
      vehicle_ids: nevoIDs
    }

    const requestURL = `${endpoints.vehicle}/fetch/filtered`; 
    return this.cancellableRequest(requestURL, 'POST', body);
  }
  // by used evs ids
  static getUsedEVImagesOverrides(id: number[]) {
    return this.cancellableRequest(endpoints.used_overrides, 'POST', { id });
  }
  
  static getVehicles (
    makes: IOption[], vehicle_type?: string, makes_only = false, 
    filters?: iFilters, models: IOption[] = [], 
    start_at?: number, page_size?: number,
    lease_only?: boolean, finance_only?: boolean
  ) {
    const body: any = {
      makes_only,
      vehicle_type,
      primary_only: true,
      active_only: true,
      lease_only: lease_only,
      finance_only: finance_only,
    }

    Backend.addArrayToBody(body, makes, 'make')
    Backend.addArrayToBody(body, models, 'model')

    if (filters) {
      for (let filter in filters) {
        if (Array.isArray(filters[filter]) && filters[filter].length !== 0) {
          Backend.addArrayToBody(body, filters[filter], filter)
        }
        else if (!!filters[filter]?.value) {
          body[filter] = filters[filter]?.value; 
        }
      }
    }

    // Ordering from low to high price for Finance by default
    if (finance_only && !!!body.order_by) {
      body.order_by = 'price-low-high'
    }
    else {
      // most-recent order_by is default
      body.order_by = body.order_by || 'most-recent'
    }

    if (start_at && page_size) {
      body.start_at = start_at;
      body.end_at = start_at + page_size;
    }

    if (makes_only) {
      body.order_by = 'a-z'
    }
    
    const requestURL = `${endpoints.vehicle}/fetch/filtered`; 

    return this.cancellableRequest(requestURL, 'POST', body);
  }

  static getFeaturedVehicles() {
    const requestURL = `${endpoints.vehicle}/fetch/featured`;

    return this.cancellableRequest(requestURL, 'GET');
  }

  static getOptions(
    make: number, model: number, commercial?: boolean, 
    lease?: boolean, finance?: boolean
  ) {
    let vehicle_type: string | undefined;

    if (commercial !== undefined) {
      vehicle_type = commercial ? vehicleTypes.commercial : vehicleTypes.private;
    }
    
    const body: any = {
      make: [make],
      model: [model],
      active_only: true,
      lease_only: lease,
      finance_only: finance,
      vehicle_type
    }
    
    const requestURL = `${endpoints.vehicle}/fetch/filtered`;

    return this.cancellableRequest(requestURL, 'POST', body);
  }
  static getVehicleByID(id: string) {
    return nevo.get(`${endpoints.vehicle}/${id}`);
  }

  static getVehicleSweepData(id: string) {
    return this.cancellableRequest(`${endpoints.vehicle}/sweep/${id}`, 'GET');
  }

  static getDealershipsByMake(makeId: string, vehicle_type?: string) {
    let requestURL = `${endpoints.dealership}/manufacturer/${makeId}`
    // adding vehicle type if specified
    if (vehicle_type) {
      requestURL += `?vehicle_type=${vehicle_type}`
    }

    return this.cancellableRequest(requestURL, 'GET');
  }

  static getDealershipById(dealer_id: number | string) {
    const requestURL = `${endpoints.dealership}/${dealer_id}`
    return this.cancellableRequest(requestURL, 'GET');
  }

  static getVariant(id: string) {
    return nevo.get(`${endpoints.vehicle}/variant/${id}`);
  }
  static getVariantCancelable(id: string) {
    const requestURL = `${endpoints.vehicle}/variant/${id}`

    return this.cancellableRequest(requestURL, 'GET');
  }

  static fetchAskNevoQuestions(start_at: number, page_size: number) {
    const end_et = start_at + page_size; 

    const requestURL = `${endpoints.form}/asknevo/?start_at=${start_at}&end_at=${end_et}`

    return this.cancellableRequest(requestURL, 'GET');
  }

  private static addValues(array: any[], values?: IOption[]) {
    if (!values?.length) return

    array.push(...values.map(({label}) => label))
  }

  static fetchLearnContent(
    contentType = "newsfeed", start_at: number, page_size: number, 
    // for video-review
    tagsIDs?: number[], makes?: IOption[], models?: IOption[], filters?: iFilters
  ) {
    const end_at = start_at + page_size;

    let base_url: string;

    if (contentType === 'newsfeed') {
      base_url = endpoints.newsfeed + "/all/?active=true";
    }
    else if (contentType === 'articles') {
      base_url = endpoints.blog + `/all/?order_by=updated_at&order=desc&active=true`;
    }
    else if (contentType === 'video-review') {
      let tags: any[] = []

      if (tagsIDs?.length) tags = tags.concat(tagsIDs)

      this.addValues(tags, makes)
      this.addValues(tags, models)
      
      if (filters?.body_type?.label && filters?.body_type?.label !== 'All') {
        let bodyType = `${filters.body_type.label}`

        if (bodyType.toLowerCase() === 'sports utility vehicle') bodyType = 'SUV'

        tags.push(bodyType)
      }

      if (!!filters?.category?.value) {
        const category = categoriesSubtitutes[filters.category.value]
        if (category) tags.push(category)
      }

      const body = {
        tags,
        operation: "AND",
        active_only: true,
        order_by: !!filters?.order_by?.value ? filters.order_by.value : "featured",
        start_at,
        end_at
      }

      return this.cancellableRequest(`${endpoints.video}/fetch/filtered`, 'POST', body);
    }
    else return undefined
    
    const requestURL = `${base_url}&start_at=${start_at}&end_at=${end_at}`

    return this.cancellableRequest(requestURL, 'GET');
  }
  
  static fetchVideos(start_at = 1, page_size = 0) {
    const end_at = start_at + page_size;

    const base_url = endpoints.video + "/all/?order_by=updated_at&order=desc&active=true";

    const requestURL = `${base_url}&start_at=${start_at}&end_at=${end_at}`

    return this.cancellableRequest(requestURL, 'GET');
  }

  // the form request for ContactUs and #AskNevo | Vehicle Forms (AskAQuestion, Callback, TestDrive)
  static formRequest(
    name: string, email: string, message: string, form_type: string, 
    action?: string, phone?: string, vehicle_id?: number, dealership_id?: number, // vehicle form needs
    finance_approved?: boolean, finance_required?: boolean, tradein_vehicle_data?: any,
    vehicle_data?: any, // sweep vehicle form needs it
    ad_dealership?: string, ad_type?: string,  // for ads
  ) {
    const data: any = {
      email,
      form_type,
      session_id: getSession(),
      form_data: JSON.stringify({
        name, message, 
        action, vehicle_id, dealership_id,
        phone: !!phone ? phone : undefined,
        finance_approved, finance_required,
        tradein_vehicle_data, vehicle_data,
        ad_dealership, ad_type
      })
    }

    if (isQAorDev()) data.qa = 'true';

    const requestURL = `${endpoints.form}/`

    return this.cancellableRequest(requestURL, 'POST', data);
  }
  
  static leaseFormRequest(
    name: string, email: string, phone: string, vehicle_id: number,
    company_name?: string, fleet_size?: string // for business form
  ) {
    const data: any = { 
      email,
      form_type: 'vehicle_lease_enquiry',
      session_id: getSession(),
      form_data: JSON.stringify({
        name, phone, vehicle_id,
        company_name, fleet_size
      })
    }

    if (isQAorDev()) data.qa = 'true';

    const requestURL = `${endpoints.form}/`

    return this.cancellableRequest(requestURL, 'POST', data);
  }

  static subscribeToNewsletter(email: string) {
    const data = {
      email,
      form_type: "newsletter",
      session_id: getSession(),
      form_data: JSON.stringify({
        status: "pending"
      })
    }

    const requestURL = `${endpoints.form}/` 

    return this.cancellableRequest(requestURL, 'POST', data);
  }
  static confirmNewsletterSubscription(key: string) {
    const requestURL = `${endpoints.form}/confirm-newsletter-email/?key=${key}`

    return this.cancellableRequest(requestURL, 'GET');
  }

  static getAllVehiclesModels() {
    const url = '/route/get_vehicles'

    return this.cancellableRequest(url, 'GET');
  }
  static getRoute(
    startAddress: string, destinationAddress: string, 
    radius = 5, vehicleModel?: IOption | null,
    share_id?: string
  ) {
    const route = JSON.stringify([
      startAddress,
      destinationAddress
    ])
    let url = `/route?route=${route}&charger_radius=${radius * 1000}`;

    if (vehicleModel) {
      url += `&vehicle_model=${vehicleModel.value}`
    }

    const session_id = getSession();

    if (!!session_id) {
      url += `&session_id=${session_id}`
    }

    if (!!share_id) {
      url += `&share_id=${share_id}`
    }

    return this.cancellableRequest(url, 'GET')
  }

  // For Hotels
  static getHotels() {
    const requestURL = `${endpoints.hotels}/all/`

    return this.cancellableRequest(requestURL, 'GET');
  }
  // End of Hotels

  // statistics
  static getChargesStats() {
    const requestURL = `${endpoints.statistics}/all/?type=route_chargers`

    return this.cancellableRequest(requestURL, 'GET');
  }
  // end of statistics

  /**
   * retrieving image from ImaginStudio
   * @param vehicle
   * @param paintId
   * @param angle
   * @param width 
   * @returns promise containing response from ImaginStudio with Image  
   */

  static getBodySize(vehicle: any) {
    let bodySize: number | string = 5; // fallback value 
    
    // for commercials use specific value (e.g. L1H2, L2H4)
    if (commercialVehicles.includes(vehicle.body_type)) {
      const regex = /L.H./

      const match = regex.exec(vehicle.trim_level) // it's encoded in the trim level

      if (match) {
        bodySize = match[0];
      }
    }
    else if (bodySize === 5 && vehicle.global_number_doors) {
      bodySize = vehicle.global_number_doors;
    }

    return bodySize
  }

  static getImage(vehicle: any, angle = "01", paintId = "imagin-grey", width = 800, year?: number) {
    let url = imaginStudioURL + "/getImage?customer=nevo&steering=rhd";

    const allProperties: any = {
      modelYear: vehicle.year,
      make: vehicle.global_data.global_make,
      modelFamily: vehicle.global_data.global_model,
      modelRange: vehicle.global_data.global_trim_level,
      modelVariant: vehicle.global_data.global_body,
      angle: angle,
      width: width,
      paintId: paintId,
      bodySize: this.getBodySize(vehicle)
    }

    // overriding properties
    if (!!vehicle.image_overrides) {
      for (const [key, value] of Object.entries(vehicle.image_overrides)) {
        allProperties[key] = value;
      }
    }

    if (!!year) {
      allProperties.modelYear = year;
    }

    // updating url with all properties
    for (const [key, value] of Object.entries(allProperties)) {
      url += `&${key}=${value}`;
    }

    const modelVariant = vehicle.global_data.global_body;
    // Zooming out the image for big vehicles
    if (modelVariant === "Chassis cab" || modelVariant === "Panel van" || modelVariant === "Platform cab") {
      if (angle === "29" || angle === "13") {
        url += "&zoomLevel=25"
      }
      else url += "&zoomLevel=0"
    }
    else if (angle === "29" || angle === "13") {
      url += "&zoomLevel=50"
    }

    url = url.replaceAll(" ", "-") // replacing white spaces with dashes

    return url
  }

  /**
   * Generating url for images from imaginStudio
   * @param make 
   * @param paints - e.g. "R2R2,D2D2,A2A2"
   * @returns promise containing hex-colors
   */
  static getHexColours(make: string, paints: string) {
    const url = imaginStudioURL + `/getPaintSwatches?customer=nevo&make=${slugify(make)}&paints=${paints}`

    return fetch(url)
  } 

  // Analytics 

  static registerUser(user_data: IAnalyticsUser) {
    if (window.QAMode) return;
    
    const url = `${endpoints.analyticsUsers}/`

    return nevo.post(url, user_data);
  }

  /**
   * @description creates/updates analytics event in database
   * @param event_data (uuid, session_id, action, data)
   * @optional @param analytics_id - if present, updates the event with this id
   * @returns 
   */
  static analyticsEvent(event_data: any, analytics_id?: number | string) {
    let url = `${endpoints.analytics}/`

    // if analytics_id is present, we should update/patch the event
    if (analytics_id) {
      url += analytics_id
      return nevo.patch(url, event_data);
    }

    return nevo.post(url, event_data); 
  }

  // End of Analytics

  static getSitemap() {
    return nevo.get(`/sitemap.xml`)
  }
}

export class SweepBackend extends Backend {
  static getManufacturers() {
    const paramStr = new URLSearchParams({ 
      unpaginated: 'true',
      anon: 'true',
      platform: "nevo"
    });

    let requestURL = `${endpoints.sweep_manufacturers}?${paramStr}`

    return this.cancellableRequest(requestURL, 'GET', undefined, sweep);
  }
  static getModels(manufacturer_ids: IOption[]) {
    const makes = manufacturer_ids.map((m: IOption) => m.value).join(',')

    const paramStr = new URLSearchParams({
      manufacturer_ids: makes,
      unpaginated: 'true',
      platform: "nevo",
    });

    let requestURL = `${endpoints.sweep_models}?${paramStr}`

    return this.cancellableRequest(requestURL, 'GET', undefined, sweep);
  }
  static getResource(resource: string) { // resource: 'locations' | 'bodies' | 'colors'
    const paramStr = new URLSearchParams({ unpaginated: 'true', anon: 'true' });

    let requestURL = `/${resource}?${paramStr}`

    return this.cancellableRequest(requestURL, 'GET', undefined, sweep); 
  }
  // result.model.manufacturer.name + "-" + result.model.name to generate vehicle url
  // just simple vehicle.id to get it
  static getUsedVehicle(vid: any) {
    return sweep.get(`${endpoints.sweep_vehicles}/${vid}?platform=nevo`);
  }

  private static processFilters(filters: any) {
    // processing fuel types
    const fuel_ids = usedFuelTypeOptions.find((f: IOption) => {
      return f.label === filters.fuel_ids.value
    })
    filters.fuel_ids = fuel_ids;

    const processedFilters: any = {};

    // parsing all filters
    for (let filter in filters) {
      if (Array.isArray(filters[filter]) && filters[filter].length !== 0) {
        this.addArrayToBody(processedFilters, filters[filter], filter)
      }
      else if (!!filters[filter]?.value) {
        processedFilters[filter] = filters[filter]?.value; 
      }
    }

    // Adjusting min year a
    processedFilters.min_year = processedFilters.min_year || MIN_YEAR_VALUE;

    // manipulate max min price
    if (processedFilters.min_price) processedFilters.min_price = processedFilters.min_price * 100;
    if (processedFilters.max_price) processedFilters.max_price = processedFilters.max_price * 100;
    
    return processedFilters;
  }

  static getUsedVehiclesCounts(filters: any) {
    const body = {
      anon: 'true',
      platform: "nevo",
      ...this.processFilters({...filters})
    }

    const params = new URLSearchParams(body);

    const requestURL = `${endpoints.sweep_feed_stats}?${params}`

    return this.cancellableRequest(requestURL, 'GET', undefined, sweep);
  }
  static getUsedVehicles(filters: any, page: number, page_size: number) {
    const body: any = {
      anon: true,
      pagination_type: "page",
      page: page, // pass current page 
      page_size: page_size, // if 12, should pass 13,
      platform: "nevo",
      ...this.processFilters({...filters}),
    }

    const params = new URLSearchParams(body);

    let requestURL = `${endpoints.sweep_feed}?${params}`

    return this.cancellableRequest(requestURL, 'GET', undefined, sweep);
  }
}