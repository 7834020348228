import links from "./links"

const vehicleTypes = {
  private: 'private',
  leasing: 'leasing',
  finance: 'finance',
  used: 'used',
  commercial: 'commercial',
}

export const vehicleSearchOptions: any[] = [
  { value: vehicleTypes.private, label: 'New Vehicles', link: `${links.private}/all`, event: 'homeNewVehiclesClick' },
  { value: vehicleTypes.leasing, label: 'Leasing', link: `${links.leasing}/all`, event: 'homeLeasingClick' },
  { value: vehicleTypes.finance, label: 'Finance', link: `${links.finance}/all`, event: 'homeFinanceClick' },
  { value: vehicleTypes.used, label: 'Used EVs', link: links.used, event: 'homeUsedVehiclesClick' },
  { value: vehicleTypes.commercial, label: 'Commercial', link: `${links.commercial}/all`, event: 'homeCommercialVehiclesClick' },
  { value: 'ev-show', label: 'Electric Vehicle Show', link: `${links.ev_talk_show_page}`, event: 'homeElectricVehicleShowClick', blue: true },
]

export default vehicleTypes